import { Observable } from "rxjs";
import { AssetsService, AssetType } from "./assetsService";
import { StandardService } from "./standardService";

interface TableConfigOverrideOptions {
    hiddenFor?: string[];
    visibleFor?: string[];
}

export interface TableConfigOverrideConfig {
    [key: string]: TableConfigOverrideOptions;
}

export class TableConfigService {

    /**
     * Fetches table configuration data either from local assets directory or remote API.
     *
     * @param {string} configKey - ConfigKey of the asset.
     * @param {any} forceLocal - (Optional ) When set to true, will not try to fetch from API but use local file.
     * @param {any} forceFallBackToLocal - (Optional ) When set to true, will force fallback to local file.
     * @param {boolean} isPublic - (Optional) When set to true, will use unauthenticated call to the backend.
     * @return {Observable<any>} An Observable that emits the fetched data.
     */
    public static getTableConfig = (configKey: string, forceLocal = false, forceFallBackToLocal = false, isPublic = false): Observable<any> => {
        return new Observable<any>((subscriber: any) => {
            AssetsService.getAsset(AssetType.TableConfig, configKey, forceLocal, forceFallBackToLocal, isPublic)
                .subscribe((json: any) => {
                    AssetsService.getAsset(AssetType.TableConfigOverride, configKey, forceLocal, forceFallBackToLocal, isPublic).subscribe({
                        next: (overrideJson: {
                            [keys: string]: {
                                visibleFor: string[]
                            }
                        }) => {
                            const selectedStandard = StandardService.getSelectedStandard();
                            const transformedTableConfig = TableConfigService.transformTableConfig(json, overrideJson, selectedStandard?.metaData as string);

                            subscriber.next(transformedTableConfig);
                            subscriber.complete();
                        },
                        error: () => {
                            subscriber.next(json);
                            subscriber.complete();
                        }
                    })
                })
        });
    }

    private static transformTableConfig = (json: any, overrideJson: TableConfigOverrideConfig, standardMetaData: string): any => {
        if (!overrideJson) {
            return json;
        }

        const keys = Object.keys(overrideJson);
        if (!keys?.length) {
            return json;
        }

        const visibilityMap = new Map<string, boolean>();
        keys.forEach((key: string) => {

            let isHiddenForStandard = false;
            if (overrideJson[key]?.hiddenFor?.length && overrideJson[key]?.hiddenFor?.includes(standardMetaData)) {
                isHiddenForStandard = true;
            }

            let isVisibleForStandard = true;
            if (overrideJson[key]?.visibleFor?.length) {
                isVisibleForStandard = overrideJson[key]?.visibleFor?.includes(standardMetaData) ? true : false;
            }

            visibilityMap.set(key,
                isHiddenForStandard
                    ? false
                    : isVisibleForStandard)
        });

        const newJson = {
            ...json,
            columns: json.columns.filter((column: any) => visibilityMap.has(column.field) ? visibilityMap.get(column.field) : true
            )
        };

        return newJson;
    }
}